import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const StyledMyProjectSettingsPreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const ItalicText = styled(RMText)`
  font-style: italic;
`;

export const ClickableText = styled(RMText)`
  cursor: pointer;
`;
