import { RMText } from '@/components/RMText/RMText';

import { ClickableText, ItalicText, StyledMyProjectSettingsPreviewItem } from './MyProjectSettingsPreviewItem.styles';

interface MyStorytellerSettingsPreviewItemProps {
  projectName: string;
  storytellerName: string;
  onManage: () => void;
}

export function MyProjectSettingsPreviewItem({
  projectName,
  storytellerName: storytellerFirstName,
  onManage,
}: MyStorytellerSettingsPreviewItemProps) {
  return (
    <StyledMyProjectSettingsPreviewItem>
      <RMText type="sans" size="s" color="on-surface-primary">
        {projectName} -{' '}
        <ItalicText type="sans" size="s" color="on-surface-primary">
          By {storytellerFirstName}
        </ItalicText>
      </RMText>
      <ClickableText type="sans" size="s" color="primary" underline onClick={onManage}>
        Project settings
      </ClickableText>
    </StyledMyProjectSettingsPreviewItem>
  );
}
