import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import {
  ButtonsWrapper,
  CancelButton,
  Content,
  FeaturesTable,
  FeaturesTableWrapper,
  LeftContent,
  RightContent,
} from './SubscriptionCancelDialog.styles';

const FEATURE_ROWS = [
  { feature: 'View existing recordings and read existing stories', alwaysAvailable: true },
  { feature: 'Order books', alwaysAvailable: true },
  { feature: 'Send new prompts', alwaysAvailable: false },
  { feature: 'Record new stories', alwaysAvailable: false },
  { feature: 'Edit existing stories', alwaysAvailable: false },
  { feature: 'Use AI-writing assistant', alwaysAvailable: false },
  { feature: 'Add, remove, or change photos', alwaysAvailable: false },
];

export interface SubscriptionCancelDialogProps {
  onCancelSubscription: () => void;
  onClose: () => void;
}

export function SubscriptionCancelDialog({ onCancelSubscription, onClose }: SubscriptionCancelDialogProps) {
  const isMobile = useIsMobileViewport();

  const buttons = (
    <ButtonsWrapper>
      <RMButton background="primary" fullWidth autoLoading onClick={onClose}>
        Keep subscription
      </RMButton>
      <CancelButton background="transparent" color="danger" fullWidth autoLoading onClick={onCancelSubscription}>
        {isMobile ? 'Cancel subscription' : 'Cancel my subscription'}
      </CancelButton>
    </ButtonsWrapper>
  );

  return (
    <RMDialog.Root open variant="extra-large" onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={isMobile ? 'Need a bit more time to finish collecting stories?' : null}
          message={
            isMobile ? 'Keep an active subscription to maintain access to your favorite Remento features.' : null
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <Content>
            {isMobile == false && (
              <LeftContent>
                <RMText type="serif" size="xl" color="on-surface-primary">
                  Need a bit more time to finish collecting stories?
                </RMText>
                <RMText type="sans" size="m" color="on-surface-primary">
                  Keep an active subscription to maintain access to your favorite Remento features.
                </RMText>
                {buttons}
              </LeftContent>
            )}

            <RightContent>
              <FeaturesTableWrapper>
                <FeaturesTable>
                  <thead>
                    <tr>
                      <th>
                        <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary" bold>
                          Features
                        </RMText>
                      </th>
                      <th>
                        <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary" bold>
                          No Subscription
                        </RMText>
                      </th>
                      <th>
                        <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary" bold>
                          Subscription
                        </RMText>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {FEATURE_ROWS.map((row) => (
                      <tr key={row.feature}>
                        <td>
                          <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary">
                            {row.feature}
                          </RMText>
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={row.alwaysAvailable ? faCircleCheck : faXmark}
                            color={ColorsTokens.primary}
                            size="xl"
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon icon={faCircleCheck} color={ColorsTokens.primary} size="xl" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </FeaturesTable>
              </FeaturesTableWrapper>
            </RightContent>
          </Content>
        </RMDialog.Body>
        {isMobile && <RMDialog.Footer>{buttons}</RMDialog.Footer>}
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
