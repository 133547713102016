import { useMemo } from 'react';

import { usePersonAvatarUrl, usePersonInitials, usePersonQuery } from '@/services/api/person';
import { usePersonUserQuery } from '@/services/api/user/user.service.hook';
import { formatPhoneNumber } from '@/utils/phone-number';

import { MyProfileSettingsPreviewSection } from '../components/MyProfileSettingsPreviewSection/MyProfileSettingsPreviewSection';

interface MyProfileSettingsPreviewContainerProps {
  personId: string;
  onEdit: () => void;
}

export function MyProfileSettingsPreviewContainer({ personId, onEdit }: MyProfileSettingsPreviewContainerProps) {
  const personUserQuery = usePersonUserQuery(personId);
  const personQuery = usePersonQuery(personId);
  const nameInitials = usePersonInitials(personQuery.data);
  const photoUrl = usePersonAvatarUrl(personQuery.data);

  const email = personUserQuery.data?.communicationChannels.email ?? '';
  const phone = useMemo(
    () => formatPhoneNumber(personUserQuery.data?.communicationChannels.phone),
    [personUserQuery.data?.communicationChannels.phone],
  );

  return (
    <MyProfileSettingsPreviewSection
      name={personQuery.data?.name?.full}
      nameInitials={nameInitials}
      photoUrl={photoUrl}
      email={email}
      phone={phone}
      onEdit={onEdit}
    />
  );
}
