import { useMemo } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubscriptionRenewalType, SubscriptionType } from '@remento/types/user';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import {
  ChangePlanWarningWrapper,
  ClickableText,
  ClickableTextWrapper,
  DialogContent,
  Divider,
  ItalicAnchor,
  PlanPickerItem,
  PriceText,
} from './SubscriptionManageDialog.styles';

export interface SubscriptionManageDialogProps {
  selectedType: SubscriptionType;
  activeType: SubscriptionType;
  renewalType: SubscriptionRenewalType | null;
  subscriptionEndsOn: number;
  onChangeType: (newPlan: SubscriptionType) => void;
  onSave: () => void;
  onUpdateBilling: () => void;
  onResumeSubscription: () => void;
  onCancelSubscription: () => void;
  onClose: () => void;
}

export function SubscriptionManageDialog({
  selectedType,
  activeType,
  renewalType,
  subscriptionEndsOn,
  onChangeType,
  onSave,
  onUpdateBilling,
  onResumeSubscription,
  onCancelSubscription,
  onClose,
}: SubscriptionManageDialogProps) {
  const isMobile = useIsMobileViewport();

  const formattedEndsOn = useMemo(() => dayjs(subscriptionEndsOn).format('MM/DD/YYYY'), [subscriptionEndsOn]);

  return (
    <RMDialogPanel.Root open onClose={onClose}>
      <RMDialogPanel.Title onClose={onClose}>Manage subscription</RMDialogPanel.Title>
      <RMDialogPanel.Content>
        <DialogContent>
          <RMText type="sans" size="s" color="on-surface-primary">
            {renewalType !== SubscriptionRenewalType.CANCEL &&
              `Your ${
                activeType === SubscriptionType.YEARLY ? 'annual' : 'monthly'
              } subscription is active and will be renewed on ${formattedEndsOn}.`}
            {renewalType === SubscriptionRenewalType.CANCEL &&
              `Your ${
                activeType === SubscriptionType.YEARLY ? 'annual' : 'monthly'
              } subscription is cancelled and will expire on ${formattedEndsOn}.`}
          </RMText>
          {/* This is just to add a spacing */}
          {isMobile == false && <div />}
          <RMText type="sans" size="xs" color="on-surface-primary" bold>
            SUBSCRIPTION TYPE
          </RMText>
          <RMPicker.Root>
            <RMPicker.Item
              label={
                <PlanPickerItem>
                  <RMText type="sans" size="s" color="on-surface-primary" bold>
                    Annual plan
                  </RMText>
                  <PriceText type="sans" size="s" color="on-surface-primary" bold>
                    $8.25 per month
                  </PriceText>
                  <RMText type="sans" size="xs" color="on-surface-tertiary">
                    $99 billed annually
                  </RMText>
                </PlanPickerItem>
              }
              checkboxAlignment={isMobile ? 'top' : 'center'}
              value={SubscriptionRenewalType.YEARLY}
              selected={selectedType === SubscriptionType.YEARLY}
              disabled={renewalType === SubscriptionRenewalType.CANCEL}
              onClick={() => {
                onChangeType(SubscriptionType.YEARLY);
              }}
            />
            <RMPicker.Item
              label={
                <PlanPickerItem>
                  <RMText type="sans" size="s" color="on-surface-primary" bold>
                    Monthly plan
                  </RMText>
                  <PriceText type="sans" size="s" color="on-surface-primary" bold>
                    $12.00 per month
                  </PriceText>
                  <RMText type="sans" size="xs" color="on-surface-tertiary">
                    Billed monthly
                  </RMText>
                </PlanPickerItem>
              }
              checkboxAlignment={isMobile ? 'top' : 'center'}
              value={SubscriptionRenewalType.MONTHLY}
              selected={selectedType === SubscriptionType.MONTHLY}
              disabled={renewalType === SubscriptionRenewalType.CANCEL}
              onClick={() => {
                onChangeType(SubscriptionType.MONTHLY);
              }}
            />
          </RMPicker.Root>

          {activeType !== selectedType && (
            <ChangePlanWarningWrapper>
              <RMText type="sans" size="s" color="on-surface-primary">
                <FontAwesomeIcon icon={faTriangleExclamation} />
                &nbsp;Your current {activeType} plan is valid until {formattedEndsOn}. After that, you’ll switch to{' '}
                {selectedType} billing.
              </RMText>
            </ChangePlanWarningWrapper>
          )}

          <ItalicAnchor
            href="https://help.remento.co/en/articles/9745813-how-does-my-remento-subscription-work"
            target="_blank"
          >
            <RMText type="sans" size="s" color="on-surface-primary">
              See what’s included in your subscription
            </RMText>
          </ItalicAnchor>

          <Divider />

          <RMText type="sans" size="xs" color="on-surface-primary" bold>
            MORE ACTIONS
          </RMText>
          <ClickableText type="sans" size="s" color="primary" onClick={onUpdateBilling}>
            Update billing
          </ClickableText>
          {renewalType !== SubscriptionRenewalType.CANCEL && (
            <ClickableTextWrapper>
              <ClickableText type="sans" size="s" color="primary" onClick={onCancelSubscription}>
                Cancel auto-renewal
              </ClickableText>
              <RMTooltip
                showOnTouch
                side={isMobile ? 'top' : 'right'}
                message="Canceling does not delete your account or stories. You will continue to have full access to Remento until the end of your current subscription period."
              >
                <FontAwesomeIcon icon={faCircleExclamation} size="sm" color={ColorsTokens.primary} />
              </RMTooltip>
            </ClickableTextWrapper>
          )}
          {renewalType === SubscriptionRenewalType.CANCEL && (
            <ClickableText type="sans" size="s" color="primary" onClick={onResumeSubscription}>
              Resume subscription
            </ClickableText>
          )}
          <a href="mailto:support@remento.co">
            <ClickableText type="sans" size="s" color="primary">
              Contact support
            </ClickableText>
          </a>
        </DialogContent>
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton
          background="primary"
          autoLoading
          fullWidth
          disabled={renewalType === SubscriptionRenewalType.CANCEL}
          onClick={onSave}
        >
          Save
        </RMButton>
      </RMDialogPanel.Actions>
    </RMDialogPanel.Root>
  );
}
