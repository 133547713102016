import { RMLink } from '@/components/RMLink/RMLink';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

interface NotificationsSettingsPreviewProps {
  onEdit: () => void;
}

export function NotificationsSettingsPreview({ onEdit }: NotificationsSettingsPreviewProps) {
  return (
    <>
      <RMText type="sans" size="s" color="on-surface-secondary">
        Manage what notifications you receive and when.
      </RMText>

      <RMSpacer spacing="md" direction="column" />

      <RMLink onClick={onEdit}>Edit notification settings</RMLink>
    </>
  );
}
