import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubscriptionRenewalType } from '@remento/types/user';

import { toast } from '@/components/RMToast/RMToast';
import { getSubscriptionCancelledSurveyPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

import { SubscriptionCancelDialog } from '../components/SubscriptionCancelDialog/SubscriptionCancelDialog';

export interface SubscriptionCancelDialogContainerProps {
  onClose: () => void;
}

export function SubscriptionCancelDialogContainer({ onClose }: SubscriptionCancelDialogContainerProps) {
  const { userService } = useServices();
  const navigate = useNavigate();

  const handleCancelSubscription = useCallback(async () => {
    try {
      await userService.updateSubscriptionRenewalType(SubscriptionRenewalType.CANCEL);
      navigate(getSubscriptionCancelledSurveyPath());
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [navigate, userService]);

  return <SubscriptionCancelDialog onCancelSubscription={handleCancelSubscription} onClose={onClose} />;
}
