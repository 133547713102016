import { createStore, StoreApi, useStore } from 'zustand';

interface DefaultState {
  type: 'default';
}

interface EditProfileState {
  type: 'edit-profile';
  personId: string;
}

interface EditNotificationSettingsState {
  type: 'edit-notification-settings';
  personId: string;
}

export type AccountSettingsDialogPanelState = DefaultState | EditProfileState | EditNotificationSettingsState;
export type AccountSettingsDialogPanelStore = StoreApi<AccountSettingsDialogPanelState>;

export function createAccountSettingsDialogPanelStore(): AccountSettingsDialogPanelStore {
  return createStore<AccountSettingsDialogPanelState>(() => ({ type: 'default' }));
}

export function openEditProfileDialog(store: AccountSettingsDialogPanelStore, personId: string): void {
  store.setState(
    {
      type: 'edit-profile',
      personId,
    },
    true,
  );
}

export function openEditNotificationsSettingsDialog(store: AccountSettingsDialogPanelStore, personId: string): void {
  store.setState(
    {
      type: 'edit-notification-settings',
      personId,
    },
    true,
  );
}

export function closeAccountSettingsDialog(store: AccountSettingsDialogPanelStore): void {
  store.setState(
    {
      type: 'default',
    },
    true,
  );
}

export function useAccountSettingsDialogPanelState(
  store: AccountSettingsDialogPanelStore,
): AccountSettingsDialogPanelState {
  return useStore(store);
}
