import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AclGroupRole } from '@remento/types/acl';
import { SubscriptionRenewalType, UserSubscriptionStatus } from '@remento/types/user';
import dayjs from 'dayjs';

import { toast } from '@/components/RMToast/RMToast';
import { getQueryParam } from '@/hooks/useQueryParam';
import { useSearchParams } from '@/hooks/useSearchParams';
import { getNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { getAccountReactivatePath, getProjectSettingsPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { getRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, useProjectsQuery } from '@/services/api/project';

import { MyProjectSettingsPreviewItem } from '../components/MyProjectSettingsPreview/MyProjectSettingsPreviewItem';
import { MyProjectSettingsPreviewRoot } from '../components/MyProjectSettingsPreview/MyProjectSettingsPreviewRoot';

import { SubscriptionManageDialogContainer } from './SubscriptionManageDialog.container';

interface MyStorytellersSettingsPreviewItemContainerProps {
  projectId: string;
  onManage: (storytellerId: string) => void;
}

const ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN];

function MyProjectsSettingsPreviewItemContainer({
  projectId,
  onManage,
}: MyStorytellersSettingsPreviewItemContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl);
  const userProjectRole = getRole(ROLES, userProjectRoles ?? []);
  const subjectPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);

  if (projectQuery.data == null || userProjectRole !== AclGroupRole.OWNER) {
    return null;
  }

  return (
    <MyProjectSettingsPreviewItem
      projectName={projectQuery.data.name}
      storytellerName={subjectPersonQuery.data?.name?.full ?? 'Someone'}
      onManage={() => onManage(projectId)}
    />
  );
}

export function MyProjectsSettingsPreviewContainer() {
  // Services
  const { webappAnalyticsService } = useServices();
  const navigate = useNavigate();
  const { setSearchParam } = useSearchParams();

  // Stores
  const navbarStore = useNavbarStore();

  // Queries
  const user = useUser();
  const projectsQuery = useProjectsQuery();
  const projectIds = projectsQuery.data ?? [];

  // State
  const [manageSubscriptionDialogOpen, setManageSubscriptionDialogOpen] = useState(false);
  const isSubscriptionActive = useMemo(
    () =>
      (user?.subscription?.status === UserSubscriptionStatus.ACTIVE ||
        user?.subscription?.status === UserSubscriptionStatus.PENDING) &&
      user?.subscription?.renewalType !== SubscriptionRenewalType.CANCEL,
    [user?.subscription?.renewalType, user?.subscription?.status],
  );
  const isSubscriptionCancelled = useMemo(
    () =>
      (user?.subscription?.status === UserSubscriptionStatus.ACTIVE ||
        user?.subscription?.status === UserSubscriptionStatus.PENDING) &&
      user?.subscription?.renewalType === SubscriptionRenewalType.CANCEL,
    [user?.subscription?.renewalType, user?.subscription?.status],
  );
  const isSubscriptionInactive = useMemo(
    () => user?.subscription?.status === UserSubscriptionStatus.INACTIVE,
    [user?.subscription?.status],
  );

  // Callbacks
  const handleManageProject = useCallback(
    (projectId: string) => {
      const currentProjectId = getNavbarProjectId(navbarStore);
      navigate(getProjectSettingsPath(projectId));

      if (currentProjectId !== projectId) {
        webappAnalyticsService.onProjectSwapped('settings');
      }
    },
    [navbarStore, navigate, webappAnalyticsService],
  );

  const handleManageSubscription = useCallback(() => {
    if (isSubscriptionInactive) {
      navigate(getAccountReactivatePath());
      return;
    }

    setManageSubscriptionDialogOpen(true);
  }, [isSubscriptionInactive, navigate]);

  // Open the manage subscription modal automatically
  useEffect(() => {
    if (getQueryParam('open-manage-subscription') !== 'true' || user?.subscription == null) {
      return;
    }

    setSearchParam('open-manage-subscription', null, { replace: true });

    // It's not possible to reactivate from the subscription dialog.
    // Redirect the user to the reactivate page.
    if (isSubscriptionInactive === true) {
      navigate(getAccountReactivatePath());
      return;
    }

    setManageSubscriptionDialogOpen(true);

    if (getQueryParam('resume-subscription') !== 'true') {
      return;
    }

    const renewOn = dayjs(user?.subscription?.endsOn).format('MM/DD/YYYY');
    if (user?.subscription?.renewalType === SubscriptionRenewalType.CANCEL) {
      toast(`Click on resume subscription to renew it on ${renewOn}`);
      return;
    }
    toast(`Your subscription is already active and set to renew on ${renewOn}`);
  }, [isSubscriptionInactive, navigate, setSearchParam, user?.subscription]);

  if (user?.subscription == null) {
    return null;
  }

  return (
    <MyProjectSettingsPreviewRoot
      isSubscriptionActive={isSubscriptionActive}
      isSubscriptionCancelled={isSubscriptionCancelled}
      isSubscriptionInactive={isSubscriptionInactive}
      subscriptionEndDate={user.subscription.endsOn}
      onManageSubscription={handleManageSubscription}
    >
      {projectIds.map((projectId) => (
        <MyProjectsSettingsPreviewItemContainer key={projectId} projectId={projectId} onManage={handleManageProject} />
      ))}

      {manageSubscriptionDialogOpen && (
        <SubscriptionManageDialogContainer onClose={() => setManageSubscriptionDialogOpen(false)} />
      )}
    </MyProjectSettingsPreviewRoot>
  );
}
